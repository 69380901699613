import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentTextField.figmaUrl.android} codeUrl={checklists.componentTextField.codeUrl.android} checklists={checklists.componentTextField.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Textfields allow user input. The border should light up simply and clearly indicating which field the user is currently editing. Textfield is a component that extends the same base component TextInput, from React Native or React Native Web`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "left"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "width": 300,
            "align": "center",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/react-native/react-native-textfield-1.png",
            "alt": "Legion Textfield React Native"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Textfield } from '@legion-crossplatform/ui'

// Inside render method
<Textfield 
  size="$4" 
  borderEndWidth={3}
/>
`}</code></pre>
    <h3>{`Textfield Inline`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Textfield } from '@legion-crossplatform/ui'

// Inside render method
<Textfield 
  inline="true"
  size="$4" 
  borderEndWidth={3} 
/>
`}</code></pre>
    <h3>{`Textfield Outline`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Textfield } from '@legion-crossplatform/ui'

// Inside render method
<Textfield 
  outline="true"
  size="$4" 
  borderEndWidth={3} 
/>
`}</code></pre>
    <h3>{`With Hint`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { TextArea } from '@legion-crossplatform/ui'

//Inside render method
<TextArea 
  inline="true"
  caption="This is a caption"
  captionType="info" 
  size="$4" 
  hint="Hint"
  borderEndWidth={3} 
/>
`}</code></pre>
    <h3>{`With Label`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Textfield } from '@legion-crossplatform/ui'

// Inside render method
<Textfield 
  inline="true"
  caption="This is a caption"
  captionType="info" 
  size="$4" 
  label="Label"
  borderEndWidth={3} 
/>
`}</code></pre>
    <h3>{`onChange`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Textfield } from '@legion-crossplatform/ui'

const [text, onChangeText] = React.useState('Useless Text');

// Inside render method

<Textfield 
  inline="true"
  caption="This is a caption"
  captionType="info" 
  size="$4" 
  label="Label"
  borderEndWidth={3} 
  onChangeText={onChangeText}
  value={text}
/>
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <p>{`Inherits the same props as TextInput from `}<a parentName="p" {...{
        "href": "https://reactnative.dev/docs/textinput"
      }}>{`React Native`}</a>{`, with following additional props:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`caption`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Caption for the textarea`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`captionType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Caption type for the textarea`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text placed under the input box to provide additional guidance or information to the user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`inline`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default: `}<inlineCode parentName="td">{`false`}</inlineCode>{`. If `}<inlineCode parentName="td">{`true`}</inlineCode>{`, applies the inline rule to the input box, making it display inline with other elements`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Label for the textarea`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback function that is called when the text input value changes. Receives the new value as an argument`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`outline`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Default: `}<inlineCode parentName="td">{`false`}</inlineCode>{`. If `}<inlineCode parentName="td">{`true`}</inlineCode>{`, applies the outline rule to the input box, making it display outline with other elements`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      